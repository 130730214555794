import { mapState, mapActions } from 'vuex';

import { PROJECT_NAME } from '@config/application';
import serverUrl from '@config/server';
import { version as uiVersion } from '../../../package.json';

export default {
  name: 'AppLogin',
  data() {
    return {
      PROJECT_NAME,
      serverUrl,
      uiVersion,
      text: [
        'This system is owned by FarHeap Solutions, Inc. If you are not ',
        'authorized to access this system, exit immediately. ',
        'Unauthorized access to this system is forbidden by company policies, ',
        'national, and international laws. ',
        'Unauthorized users are subject to criminal and civil penalties as well ',
        'as company initiated disciplinary proceedings. ',
        'By entering this system you acknowledge that you have authorized access ',
        'and appropriate level of privilege within the system.',
      ].join(''),
      copyright: `© Opensoft - ${new Date().getFullYear()}`,
    };
  },
  computed: {
    ...mapState('application', ['apiVersion']),
  },
  methods: {
    ...mapActions('authenticator', ['login']),
  },
};
